//Menu
var wrapperMenu = document.querySelector('.menu');
var overlay = document.querySelector('.overlay');
var link = document.querySelector('.link');
var formulaire = document.querySelector('.formulaire');
var contact = document.querySelector('.date');
var fermer = document.querySelector('.fermer');


wrapperMenu.addEventListener('click', function () {
  wrapperMenu.classList.toggle('open');
  overlay.classList.toggle('open');
});


link.addEventListener('click', function () {
    wrapperMenu.classList.remove('open');
    overlay.classList.remove('open');
});











var hauteur = window.innerHeight;
var mq = window.matchMedia('(max-width: 500px)');
var lastHeight = window.innerHeight;
var lastWidth = window.innerWidth;


    if (mq.matches) {
      window.addEventListener('resize', (e) => {
        if (lastWidth == window.innerWidth && lastHeight != window.innerHeight) {
          console.log("resize vertical");
        } else if (lastWidth != window.innerWidth && lastHeight == window.innerHeight) {
          window.location = window.location.href;
          console.log("resize horizental");
        }
      });


    } else {


      window.addEventListener('resize', (e) => {
        if (lastWidth == window.innerWidth && lastHeight != window.innerHeight) {
          console.log("resize vertical");
        } else if (lastWidth != window.innerWidth && lastHeight == window.innerHeight) {
          window.location = window.location.href;
          console.log("resize horizental");
        }
      });


      const scrollRoot = document.querySelector('main')

      const scrollHorizontal = (ev) => {
           let deltaCoef = 1, 
               deltaCoefLimit = 3, 
               deltaCoefInterval = 1
           
           let deltaTimer = setInterval(() => {       
             scrollRoot.scrollLeft += ev.deltaY      
             deltaCoef+=deltaCoefInterval
                  
             if (deltaCoef >= deltaCoefLimit) {
               clearInterval(deltaTimer) 
             }
           }       
          , 1)    
          ev.preventDefault()
      }
      
      
      (() => {
        scrollRoot.addEventListener(
          'wheel', 
          scrollHorizontal, 
          { passive: false }
        )  
      })()


    };

    console.log(hauteur);














const anim2 = document.querySelector('.anim__resume');
const anim3 = document.querySelector('.anim__birthday');
const main = document.querySelector('main');

//const anim11 = document.querySelector('.welcome');


window.onload = function () {
  //anim11.classList.add('anim1');

anim2.classList.add('start__anim2');
anim3.classList.add('start__anim2');

}


const animatedElements = document.querySelectorAll('.anim__crea1')
const animatedElementss = document.querySelectorAll('.anim__crea2')

const anim4 = document.querySelectorAll('.anim__post');


const isElementInViewPort = (el) => {
  let windowViewWidth = window.innerWidth
  let { width, left } = el.getBoundingClientRect()
  return left < (windowViewWidth - width)
}

main.addEventListener('scroll', (ev) => { 
  animatedElements.forEach(el => {
    if (isElementInViewPort(el) === true) {
      el.classList.add('start__anim3')
    }
  })
  console.log("lololo");

});

  main.addEventListener('scroll', (ev) => { 
    animatedElementss.forEach(el => {
      if (isElementInViewPort(el) === true) {
  el.classList.add('start__anim4');
}

});


main.addEventListener('scroll', (ev) => { 
  anim4.forEach(el => {
    if (isElementInViewPort(el) === true) {
el.classList.add('start__anim3');
}

});




});

});





